* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.navlogo {
  width: 200px;
}
body {
  background-color: hsl(240, 4.76%, 8.24%);
}
html {
  font-size: 10px;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 10vh;
}
a {
  text-decoration: none;
}
.home_cotent {
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 150px;
  position: relative;
}
.main-btn {
  position: relative;
  margin: 0;
  padding: 15px 20px;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  font-family: "Source Sans Pro", sans-serif;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.main-btn:hover {
  animation: sh0 0.5s ease-in-out both;
}

@keyframes sh0 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(7deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

.main-btn:hover span {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

.main-btn::before,
.main-btn::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1),
    opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  z-index: -1;
  transform: translate(100%, -25%) translate3d(0, 0, 0);
}

.main-btn:hover::before,
.main-btn:hover::after {
  opacity: 0.15;
  transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1),
    opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.main-btn:hover::before {
  transform: translate3d(50%, 0, 0) scale(0.9);
}

.main-btn:hover::after {
  transform: translate(50%, 0)  scale(1.1);
}
