.skill_section {
  padding-top: 150px;
  padding-bottom: 100px;
}
.skill_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.skill_content .heading {
  margin: 20px 0px;
}
.skill_content span {
  color: rgba(255, 255, 255, 0.589);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 100;
}
.skill_content .para {
  max-width: 750px;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;
  /* word-spacing: 2px; */
  color: white;
}
.tailwindlogo {
  margin-top: 20px;
}
.pararedux {
  margin-right: 20px;
}
.restlogo img {
  width: 80px;
  margin-top: 20px;
}
