.projects_grid {
  display: grid;
  gap: 40px;
  row-gap: 60px;
  grid-template-columns: 1fr 1fr 1fr;
}

.project_img1 {
  background-image: url("../images/banklist.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-blend-mode: color-burn;
}
.project_description {
  display: flex;
  flex-direction: column;

  justify-content: end;
}
.main_btn {
  color: white;
  font-size: 18px;
}
.project_img2 {
  background-image: url("../images/C1_page-0001.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-blend-mode: color-burn;
}
.project_img3 {
  background-image: url("../images/WhatsApp\ Image\ 2024-03-24\ at\ 03.20.15_d5f54f1e.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  height: 200px;
  border-radius: 10px;
  background-blend-mode: color-burn;
}
.project_img4 {
  background-image: url("../images/WhatsApp\ Image\ 2024-03-24\ at\ 03.21.46_17cff455.jpg");
  background-position: center;
  background-size: 120%;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-blend-mode: color-burn;
}
.project_img5 {
  /* background-image: url("../images/green.jpg"); */
  background-position: center;
  background-size: 120%;
  width: 100%;
  background-repeat: no-repeat;
  height: 200px;
  border-radius: 10px;
  background-blend-mode: color-burn;
}
.project_img6 {
  background-image: url("../images/WhatsApp Image 2024-03-24 at 03.50.15_9929cf69.jpg");
  background-position: center;
  background-size: 120%;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-blend-mode: color-burn;
}
.project_heading {
  color: white;
  font-size: 25px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.projects_col1 {
  box-shadow: 0px 0px 40px rgba(255, 166, 0, 0.877);
  border-radius: 10px;
  background-color: rgba(255, 166, 0, 0.877);
  text-align: center;
}
.projects_col2 {
  box-shadow: 0px 0px 40px rgb(200, 15, 46);
  border-radius: 10px;
  background-color: rgb(200, 15, 46);
  text-align: center;
}
.projects_col3 {
  box-shadow: 0px 0px 40px rgb(69, 66, 62);
  border-radius: 10px;
  background-color: #141416;
  text-align: center;
}
.projects_col4 {
  box-shadow: 0px 0px 40px rgb(36, 99, 104);
  border-radius: 10px;
  background-color: rgb(36, 99, 104);
  text-align: center;
}
.projects_col5 {
  box-shadow: 0px 0px 40px rgb(121, 162, 5);
  border-radius: 10px;
  background-color: rgb(121, 162, 5);
  text-align: center;
}
.projects_heading {
  margin-bottom: 20px;
}
.projects_col6 {
  box-shadow: 0px 0px 40px rgb(100, 52, 30);
  border-radius: 10px;
  background-color: rgb(100, 52, 30);
  text-align: center;
}
.project_description {
  padding: 20px 20px;
}
.project_description .project_description .para {
  font-size: 16px;
}
.projectmain_heading {
  text-align: center;
  margin: auto;
  max-width: 500px;
  margin-bottom: 50px;
}
.project_heading .para {
  text-align: center;
  max-width: 600px;

  color: #bbbbc0;
}
