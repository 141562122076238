#svg2 {
  max-width: 400px;
}

#path3336:hover {
  fill: red;
}

#path3844:hover {
  fill: red !important;
}

#path3816:hover {
  fill: red !important;
}

#path3832:hover {
  fill: red !important;
}

#path1434:hover {
  fill: red !important;
}

#path2608:hover {
  fill: red !important;
}

#path2606:hover {
  fill: red !important;
}

#path3336 {
  fill: red !important;
}
#path3868,
#path3876,
#path268,
#path82,
#path182,
#path190,
#path198,
#path206,
#path272,
#path276,
#path280,
#path284,
#path288,
#path3836,
#path18,
#path22,
#path26,
#path30,
#path34,
#path38 #path16,
#path3832,
#path3800,
#path3804,
#path3812,
#path3808,
#path3796,
#path3812,
#path3772,
#path3784,
#path3848,
#path3856,
#path3860,
#path3852,
#path3872,
#path3788,
#path3780,
#path3792 {
  /* fill: red !important; */
  animation: moveupdown 2s infinite linear alternate;
}

@keyframes moveupdown {
  0% {
    transform: translateY(-300px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(300px);
  }
}
