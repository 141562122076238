.container {
  max-width: 1200px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.section-home {
  padding-top: 150px;
  padding-bottom: 150px;
}

.section-home h1 {
  color: white;
  line-height: 1.4;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}
.section-home span {
  display: inline-block;
}

@keyframes write {
  from {
    opacity: 0;
    width: 0;
    border-right: 2px solid rgba(255, 255, 255, 0.348);
  }
  to {
    opacity: 1;
    width: 100%;
    border-right: 2px solid transparent;
  }
}
@keyframes latewrite {
  from {
    opacity: 0;
    width: 0;
    border-right: 2px solid white;
  }
  to {
    opacity: 1;
    width: 100%;
    border-right: 2px solid transparent;
  }
}
.writing-animation {
  margin-top: 50px;
  font-size: 6.5rem;
  overflow: hidden;
  white-space: nowrap;
  animation: write 2s forwards;
}
.late-writing-animation {
  font-size: 3rem;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  animation: latewrite 2s 2s forwards;
}
.name {
  color: hsl(291.24, 63.72%, 42.16%) !important;
}
.para {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  color: rgb(255, 255, 255);
  margin: 20px 0px;
  max-width: 500px;
  font-weight: 400;
  line-height: 1.4;
}
.about-btn {
  font-size: 18px;
  color: hsl(291.24, 63.72%, 42.16%);
  font-family: "Source Sans Pro", sans-serif;
  padding-bottom: 8px;
  border-bottom: 1px solid hsl(291.24, 63.72%, 42.16%);
}
.about-btn {
  cursor: pointer;
}
.triangle img {
  max-width: 200px;
  position: absolute;
  right: -150px;
  top: 0px;
  animation: rotatepicture linear 4s infinite;
}
.home_txt {
  position: relative;
}
@keyframes rotatepicture {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
/* section about  */
.section-about {
  padding: 150px 0px;
}
.about_main {
  display: flex;

  gap: 80px;
}
.daniel_img img {
  height: 380px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.896);
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
  margin-top: 50px !important;
}

.loader_heading {
  position: relative;
}
.about_content .para {
  line-height: 1.6;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.heading {
  margin-top: 80px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 6rem;
  color: white;
  margin-bottom: 10px;
}
.aboutkeyword {
  color: rgba(255, 255, 255, 0.49);
}
.skill_content a {
  color: orange;
}
.languages_section {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
}
.languages_section div {
  flex: 0 0 100px;
}
.languages_section p {
  text-align: center;
}
.daniel_img {
}
