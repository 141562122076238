.main-div-form {
  padding: 50px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.main-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
#contact-section {
  margin-top: 60px;
}
.form-main {
  padding: 4px;
  background-image: linear-gradient(163deg, white 60%, orange 400px);
  border-radius: 60px;
  transition: all 0.25s cubic-bezier(0, 0, 0, 1);
}
form {
  border-radius: 60px;
  padding: 40px;
  background-color: rgb(20, 20, 22);
}

form input {
  outline: none;
  border-radius: 30px;
  border: none;
  padding: 30px 30px;
  width: 100%;
  background-color: rgb(20, 20, 22);
  font-family: "Space Grotesk", sans-serif;
  line-height: 1.6;
  font-size: 18px;
  color: white;
  letter-spacing: 1px;
  border: 1px solid white;
}
form input:hover {
  background-color: black;
}

form input::placeholder {
  font-family: "Space Grotesk", sans-serif;
  line-height: 1.6;
  font-size: 18px;
  color: white;
  letter-spacing: 1px;
}
form textarea {
  width: 100%;
  margin-top: 20px;
  outline: none;
  font-size: 18px;
  color: white;
  border-radius: 30px;
  border: none;
  padding: 20px;
  background-color: rgb(20, 20, 22);
  border: 1px solid white;
}
form textarea::placeholder {
  font-family: "Space Grotesk", sans-serif;
  line-height: 1.6;
  font-size: 18px;
  color: white;
}
form .main-btn {
  margin-top: 20px;
}

.form-main:hover {
  box-shadow: 0px 0px 30px 1px rgba(255, 166, 0, 0.315);
}

.form_btn {
  display: flex;
  /* justify-content: end; */
  /* background-color: red; */
}

.form_submit_btn {
  font-family: inherit;
  font-size: 20px;
  /* background-image: linear-gradient(163deg, orange 0%, rgb(20, 20, 22) 100%); */
  background-color: rgb(20, 20, 22);
  border: 1px solid white;
  color: white;
  padding: 0.7em 2em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  margin-top: 20px;
}

.form_submit_btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.form_submit_btn svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.form_submit_btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.form_submit_btn:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.form_submit_btn:hover span {
  transform: translateX(5em);
}

.form_submit_btn:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}
#accountLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2%;
}
a {
  color: black;
  text-decoration: none;
}

#earth {
  position: relative;
  top: 100px;
  left: 50px;
  width: 400px;
  height: 400px;
  margin: auto;
  border-radius: 50%;
  background: transparent
    url("https://pngimg.com/uploads/world_map/world_map_PNG1.png");

  background-size: cover;
  box-shadow: inset 0px -20px 50px 10px rgba(0, 0, 0, 0.5),
    0px 0px 30px 6px rgba(0, 0, 0, 0.44);
  transform-style: preserve-3d;
  animation: rotate 15s linear infinite;
}
.contactB {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .whatsLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    img {
      width: 50px;
      height: 50px;
    }
    a {
      text-decoration: none;
      color: #e4232c;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
@keyframes rotate {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 530px 0;
  }
}
@media (max-width: 800px) {
  /* form input {
    outline: none;
    border-radius: 30px;
    border: none;
    padding: 15px 0px;
    background-color: rgb(20, 20, 22);
    font-family: "Space Grotesk", sans-serif;
    line-height: 1.6;
    font-size: 18px;
    color: white;
    letter-spacing: 1px;
    border: 1px solid white;
  } */
  .main-div-form {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 50px;
  }
  #earth {
    position: relative;
    top: 100px;
    left: 50px;
    width: 300px;
    height: 300px;
  }
  form input {
    outline: none;
    border-radius: 30px;
    border: none;
    padding: 20px 20px;
    width: 100%;
    background-color: rgb(20, 20, 22);
    font-family: "Space Grotesk", sans-serif;
    line-height: 1.6;
    font-size: 18px;
    color: white;
    letter-spacing: 1px;
    border: 1px solid white;
  }
  .main-inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  #earth {
    position: relative;
    top: 100px;
    left: 0px;
    width: 300px;
    height: 300px;
  }
}
