@media (max-width: 1100px) {
  html {
    font-size: 8px;
  }
  .home_cotent {
    grid-template-columns: 1fr;
    gap: 60px;
  }
  .home_txt {
    text-align: center;
  }

  .home_cotent .para {
    text-align: center;
    max-width: 1100px;
    padding: 0 50px;
  }
  #svg2 {
    max-width: 600px;
  }
  .home-img {
    text-align: center;
  }
  .triangle {
    display: none;
  }
}
@media (max-width: 1000px) {
  .about_main {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .about_content {
    text-align: center;
    max-width: 900px;
  }
  .para {
    max-width: 700px;
  }
  .about_main {
    flex-direction: column-reverse;
  }
}
@media (max-width: 900px) {
  .projects_grid {
    display: grid;
    gap: 40px;
    row-gap: 60px;
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (max-width: 700px) {
  .languages_section div {
    flex: 0 0 80px !important;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 7px;
  }
  .home_cotent .para {
    text-align: center;
    max-width: 1100px;
    padding: 0 20px;
  }
  .section-home {
    padding-top: 100px;
  }
  .languages_section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
    align-items: center;
    margin-top: 50px;
  }
  .daniel_img img {
    width: 300px;
  }
  .projects_grid {
    display: grid;
    gap: 60px !important;
    row-gap: 60px;
    grid-template-columns: 1fr !important;
  }
  form {
    border-radius: 10px !important;
    padding: 30px 10px !important;
  }
  .form-main {
    border-radius: 10px !important;
  }
  input,
  textarea {
    border-radius: 10px !important;
  }
  .form_submit_btn {
    border-radius: 10px;
  }
  .skill_content span {
    text-align: center;
    line-height: 1.6;
  }
  .heading {
    text-align: center;
  }
}
