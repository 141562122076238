.footermain {
  padding: 20px 0px;
  display: flex;

  color: white;
  font-size: 16px;
  border-top: 1px solid white;
  justify-content: space-between;
}
.footermain p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
}
.footermain i {
  font-size: 30px;
  margin-left: 20px;
  color: white !important;
}
